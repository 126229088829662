var GACode = "G-38ZC9RT059";
var gtmElem = document.createElement("script");
gtmElem.src = "https://www.googletagmanager.com/gtag/js?id=" + GACode;
gtmElem.async = true;
document.querySelector("head").appendChild(gtmElem);

 //can be removed after july 2023
 (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
  (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
  m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
  })(window,document,'script','//www.google-analytics.com/analytics.js','ga');

  ga('create', 'UA-6463692-1', 'auto');
  ga('send', 'pageview');